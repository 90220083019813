import isCurrentPage from "../isCurrentPage";
import currentYear from "../currentYear";
import toggleMainContent from "../../components/toggleMainContent";
import fixedHeader from "../../components/fixedHeader";
import toggleCallback from "../../components/toggleCallback";
import sendForm from "../../components/callbackForm";

export default function routingFunctions() {
  isCurrentPage();
  currentYear();
  toggleMainContent();
  fixedHeader();
  toggleCallback();
  sendForm();
}