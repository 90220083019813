const validateName = (name) => {
  if (name.length >= 2 && name.length < 250) {
    return name.match(
      /^[а-яА-Яa-zA-Z]/
    );
  }
}

const validateEmail = (email) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

const validatedName = () => {
  let name = $('input[name="name"]');
  let nameValue = $('input[name="name"]').val();
  if (validateName(nameValue)) {
    name.removeClass('error').addClass('valid');
  } else {
    name.addClass('error').removeClass('valid');
  }
  if (nameValue == '') {
    name.removeClass('error').removeClass('valid');
  }
  return false;
}

const validatedEmail = () => {
  let email = $('input[name="email"]');
  let emailValue = $('input[name="email"]').val();
  if (validateEmail(emailValue)) {
    email.removeClass('error').addClass('valid');
  } else {
    email.addClass('error').removeClass('valid');
  }
  if (emailValue == '') {
    email.removeClass('error').removeClass('valid');
  }
  return false;
}

const validatedTextarea = () => {
  let textarea = $('textarea[name="idea"]');
  let textareaValue = $('textarea[name="idea"]').val();
  if (textareaValue.length >= 2) {
    textarea.removeClass('error').addClass('valid');
  } else {
    textarea.addClass('error').removeClass('valid');
  }
  if (textareaValue == '') {
    textarea.removeClass('error').removeClass('valid');
  }
  return false;
}

$('input[name="name"]').on('input', validatedName);
$('input[name="email"]').on('input', validatedEmail);
$('textarea[name="idea"]').on('input', validatedTextarea);

export default function sendForm() {
  let form = $('form');
  if (form) {
    form.submit(function(e) {
      e.preventDefault();
      if (
        $('input[name="name"]').hasClass('valid') &&
        $('input[name="email"]').hasClass('valid') &&
        $('textarea[name="idea"]').hasClass('valid')
        ) {
        fetch('assets/handlers/callback.php', {
          method: 'POST',
          body: new FormData(form.get(0))
        }).then(() => {
          $('.callback__wrapper').fadeOut(function() {
            $('.callback form').hide();
            $('.callback__title h2').text('Ваше сообщение отправлено!');
            $('input[name="name"], input[name="email"], textarea[name="idea"]').removeClass('valid');
            form.get(0).reset();
            $('.callback__wrapper').fadeIn(function() {
              setTimeout(function() {
                $('.callback__close').trigger('click');
              }, 2000);
            });
          });
        });
      }
    });
  }
}
sendForm();