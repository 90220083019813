export default function toggleMainContent() {
  let buttonHTML = $('.html');
  let buttonCSS = $('.css');
  let buttonJS = $('.javascript');
  buttonHTML.on('click', function() {
    if (!$(this).hasClass('html-open')) {
      $(this)
        .text('Pug, EJS')
        .addClass('html-open');
    } else {
      $(this)
        .text('HTML')
        .removeClass('html-open');
    }
  });
  buttonCSS.on('click', function() {
    if (!$(this).hasClass('css-open')) {
      $(this)
        .text('SCSS, Tailwind, BEM')
        .addClass('css-open');
    } else {
      $(this)
        .text('CSS')
        .removeClass('css-open');
    }
  });
  buttonJS.on('click', function() {
    if (!$(this).hasClass('javascript-open')) {
      $(this)
      .text('Vue, VuePress, GSAP, Barba, jQuery')
        .addClass('javascript-open');
    } else {
      $(this)
        .text('JavaScript')
        .removeClass('javascript-open');
    }
  });
}
toggleMainContent();